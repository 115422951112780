import {useState, useEffect} from 'react'

const Counter = ({paused, onFinish, duration}) => {
    const [seconds, setSeconds] = useState(duration)

    useEffect(() => {
        if (paused || seconds < 0) {
            return null;
        }

        const timeout = setTimeout(() => {
            const nextSeconds = seconds - 1;
            setSeconds(nextSeconds);
            if (nextSeconds <= 0) {
                onFinish()
            }
        }, 1000);

        return () => clearTimeout(timeout)
    }, [seconds, paused, onFinish])

    useEffect(() => {
        setSeconds(duration)
    }, [duration])

    return <p className="game-counter">{seconds}</p>;
}

export default Counter
