import React from "react";
import {Link} from "react-router-dom";
import {IoMdArrowRoundBack} from "react-icons/io";

const BackButton = () => {
    return (
        <Link to="/">
            <IoMdArrowRoundBack className="back-button icon-button"/>
        </Link>
    );
};

export default BackButton;
