import {useEffect} from 'react'
import BackButton from "../components/back-button";
import {useGame} from "../hooks/use-game";
import GameControls from "../components/game-controls";
import IntlText from "../components/intl-text";
import {logAnalyticsEvent} from "../helpers/analytics";

const Game = () => {
    const {isDay, day, endGame} = useGame();

    useEffect(() => {
      logAnalyticsEvent('start_game')
    }, [])

    return (
        <div className={`background ${isDay ? "day-background" : "night-background"}`}>
            <BackButton/>
            <h2 className="game-text">
                <IntlText id={`game.${isDay ? "day" : "night"}`}/>{` ${day}`}
            </h2>
            <GameControls/>
            <button onClick={endGame} className="game-end-button">
                <IntlText id="game.end" />
            </button>
        </div>
    )
}

export default Game
