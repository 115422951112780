import React, {useState} from "react";
import {IoMdPause, IoMdPlay} from "react-icons/io";

const PlayButton = ({onClick}) => {
    const [playing, setPlaying] = useState(true);

    return (
        <div
            className="icon-button play-button"
            onClick={e => {
                setPlaying(!playing);
                onClick(e);
            }}
        >
            {playing ? <IoMdPause/> : <IoMdPlay/>}
        </div>
    );
};

export default PlayButton;
