import raw from "raw.macro";

export default {
    home: {
        play: "Spielen",
        instructions: "Anleitung"
    },
    game: {
        day: "Tag",
        night: "Nacht",
        end: 'Spiel beenden',
    },
    endgame: {
        who: 'Wer gewann das Spiel?',
        assassin: 'Attentäter',
        innocent: 'Unschuldige',
    },
    settings: {
        language: "Sprache"
    },
    safari: {
        unavailable: "Diese Anwendung funktioniert nicht auf Safari",
        chrome: "Verwenden Sie stattdessen Google Chrome"
    },
    lore: {
        title: "Geschichte",
        content: raw("./lore.md")
    },
    instructions: {
        title: "Spielaufbau",
        content: raw("./instructions.md")
    }
}
