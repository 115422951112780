import raw from "raw.macro";

export default {
    home: {
        play: "Jugar",
        instructions: "Instrucciones"
    },
    game: {
        day: "Dia",
        night: "Noche",
        end: 'Acabar partida',
    },
    endgame: {
        who: '¿Quién ha ganado?',
        assassin: 'Asesino',
        innocent: 'Inocentes',
    },
    settings: {
        language: "Idioma"
    },
    safari: {
        unavailable: "Esta aplicación no funciona en Safari",
        chrome: "Utiliza Google Chrome en su lugar"
    },
    lore: {
        title: "Historia",
        content: raw("./lore.md")
    },
    instructions: {
        title: "Como jugar",
        content: raw("./instructions.md")
    }
}
