import {useEffect} from "react";
import {Link, useHistory} from "react-router-dom";
import SettingsButton from "../components/settings-button";
import {useGame} from "../hooks/use-game";
import {getRuntimePlatform} from "../helpers/browser";
import blizzardSound from "../sounds/blizzard.mp3";
import Logo from "../components/logo";
import IntlText from "../components/intl-text";

const Home = () => {
    const {resetGame} = useGame();
    const history = useHistory()

    useEffect(() => {
        if (getRuntimePlatform() === 'ios') {
            history.push("/safari");
        } else {
            resetGame();
        }
    }, [history, resetGame]);

    return (
        <div className="home-background background">
            <audio src={blizzardSound} autoPlay loop/>
            <Logo/>
            <SettingsButton/>
            <Link to="/game" className="home-button">
                <IntlText id="home.play"/>
            </Link>
            <Link to="/instructions" className="home-button">
                <IntlText id="home.instructions"/>
            </Link>
            {/*{!isNativeRuntime() && (*/}
            {/*    <a href="https://play.google.com/store/apps/details?id=com.atomogames.tennights">*/}
            {/*        <img src={playStoreLogo} alt="google play store"/>*/}
            {/*    </a>*/}
            {/*)}*/}
        </div>
    );
};

export default Home;
