import IntlText from "../components/intl-text";
import {logAnalyticsEvent} from "../helpers/analytics";
import {useGame} from "../hooks/use-game";
import {useHistory} from "react-router-dom";

const EndGame = () => {
    const {day, isDay} = useGame()
    const history = useHistory()

    const handleEnd = winner => () => {
        logAnalyticsEvent('end_game', {
            day,
            winner,
            phase: isDay ? 'day' : 'night',
        })
        history.push('/')
    };

    return (
        <main className="background endgame-background">
            <h1>
                <IntlText id="endgame.who"/>
            </h1>
            <div className="endgame-buttons">
                <button className="endgame-button assassin-button" onClick={handleEnd('assassin')}>
                    <IntlText id="endgame.assassin"/>
                </button>
                <button className="endgame-button innocent-button" onClick={handleEnd('innocent')}>
                    <IntlText id="endgame.innocent"/>
                </button>
            </div>
        </main>
    )
}

export default EndGame
