import Select from "react-select";
import {useIntl} from "../hooks/use-intl";
import BackButton from "../components/back-button";
import IntlText from "../components/intl-text";

const Settings = () => {
    const {currentLanguage, updateLanguage, availableLanguages} = useIntl()
    const defaultValue = {
        value: currentLanguage,
        label: availableLanguages.find(({value}) => value === currentLanguage).label
    };
    return (
        <div className="background settings-background">
            <BackButton/>
            <h2 className="settings-header">
                <IntlText id="settings.language"/>
            </h2>
            <Select
                className="dropdown-container"
                classNamePrefix="dropdown"
                options={availableLanguages}
                defaultValue={defaultValue}
                isSearchable={false}
                isClearable={false}
                hideSelectedOptions={true}
                onChange={({value}) => updateLanguage(value)}
            />
        </div>
    );
};

export default Settings;
