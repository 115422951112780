import {Capacitor} from "@capacitor/core";

export const getRuntimePlatform = () => {
    return Capacitor.getPlatform();
}

export const isNativeRuntime = () => {
    return Capacitor.isNative
}

