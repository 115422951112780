import {useIntl} from "../hooks/use-intl";

const IntlText = ({id}) => {
    const {getTranslation} = useIntl();
    const translation = getTranslation(id);
    if (!translation) {
        throw new Error(`Translation with id ${id} does not exist`)
    }
    return translation;
};

export default IntlText;
