import {Plugins} from '@capacitor/core';

const {Device} = Plugins;

export const getDeviceLanguageCode = async () => {
    const {value} = await Device.getLanguageCode()
    return value.slice(0, 2)
}


