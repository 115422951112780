import {useState, useRef} from "react";
import {useGame} from "../hooks/use-game";
import PlayButton from "./play-button";
import Counter from "./counter";

const GameControls = () => {
    const {duration, sound, nextPhase} = useGame();
    const [paused, setPaused] = useState(false)
    const audioRef = useRef();

    const toggleTime = () => {
        const audio = audioRef.current;
        if (paused) {
            setPaused(!paused)
            audio.play();
        } else {
            setPaused(!paused)
            audio.pause();
        }
    };

    return (
        <div>
            <audio ref={audioRef} src={sound} autoPlay/>
            <PlayButton onClick={toggleTime}/>
            <Counter paused={paused} duration={duration} onFinish={nextPhase}/>
        </div>
    );
};

export default GameControls
