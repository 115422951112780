import firebase from "firebase/app";
import "firebase/analytics";

firebase.initializeApp({
  apiKey: "AIzaSyD-191S4dZfd7ppvW0PC71KQxuhhJPKMJU",
  authDomain: "ten-nights-game.firebaseapp.com",
  projectId: "ten-nights-game",
  storageBucket: "ten-nights-game.appspot.com",
  messagingSenderId: "344626528148",
  appId: "1:344626528148:web:bd783a2a6b9b18f9475981",
  measurementId: "G-EQK9VFSQ4L"
})

const analytics = firebase.analytics()

analytics.setAnalyticsCollectionEnabled(process.env.NODE_ENV === 'production')

export const logAnalyticsEvent = (name, properties) => {
  analytics.logEvent(name, properties)
}

