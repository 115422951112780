import safariLogo from "../images/safari-logo.png";
import appStore from "../images/app-store.png";
import IntlText from "../components/intl-text";

const Safari = () => (
    <div className="background">
        <img className="safari-logo" src={safariLogo} alt="safari logo"/>
        <h2 className="safari-header">
            <IntlText id="safari.unavailable"/>
        </h2>
        <h2 className="safari-text">
            <IntlText id="safari.chrome"/>
        </h2>
        <a href="https://apps.apple.com/us/app/google-chrome/id535886823">
            <img className="apple-download" src={appStore} alt="app store"/>
        </a>
    </div>
);

export default Safari;
