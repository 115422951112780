import BackButton from "../components/back-button";
import IntlText from "../components/intl-text";
import IntlRenderer from "../components/intl-renderer";

const Instructions = () => {
    return (
        <div className="background instructions-background">
            <BackButton/>
            <section className="instructions-section">
                <h2 className="instructions-title">
                    <IntlText id="lore.title"/>
                </h2>
                <div className="instructions-text">
                    <IntlRenderer id="lore.content"/>
                </div>
            </section>
            <section className="instructions-section">
                <h2 className="instructions-title">
                    <IntlText id="instructions.title"/>
                </h2>
                <div className="instructions-text">
                    <IntlRenderer id="instructions.content"/>
                </div>
            </section>
        </div>
    );
};

export default Instructions;
