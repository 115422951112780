import React from "react";
import {Link} from "react-router-dom";
import {IoMdSettings} from "react-icons/io";

const SettingsButton = () => {
    return (
        <Link to="/settings">
            <IoMdSettings className="settings-button icon-button"/>
        </Link>
    );
};

export default SettingsButton;
