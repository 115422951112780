import raw from "raw.macro";

export default {
    home: {
        play: "Joer",
        instructions: "Instructions"
    },
    game: {
        day: "Jour",
        night: "Nuit",
        end: 'Fin du jeu',
    },
    endgame: {
        who: 'Qui a gagné le jeu?',
        assassin: 'Assassin',
        innocent: 'Innocents',
    },
    settings: {
        language: "Langue"
    },
    safari: {
        unavailable: "Cette application ne fonctionne pas dans Safari",
        chrome: "Utilisez plutôt Google Chrome"
    },
    lore: {
        title: "L’Histoire",
        content: raw("./lore.md")
    },
    instructions: {
        title: "Comment jouer",
        content: raw("./instructions.md")
    }
}
