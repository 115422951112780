import {BrowserRouter, Route, Switch} from "react-router-dom";
import LanguageProvider from "../hooks/use-intl";
import GameProvider from "../hooks/use-game";
import Home from "../pages/home";
import Instructions from "../pages/instructions";
import Settings from "../pages/settings";
import Safari from "../pages/safari";
import Game from "../pages/game";
import EndGame from '../pages/endgame'
import PrivacyPolicy from "../pages/privacy-policy";

const App = () => (
    <BrowserRouter>
        <LanguageProvider>
            <GameProvider>
                <Switch>
                    <Route exact path="/" component={Home}/>
                    <Route path="/game" component={Game}/>
                    <Route path="/endgame" component={EndGame}/>
                    <Route path="/instructions" component={Instructions}/>
                    <Route path="/settings" component={Settings}/>
                    <Route path="/safari" component={Safari}/>
                    <Route path="/privacy-policy" component={PrivacyPolicy}/>
                </Switch>
            </GameProvider>
        </LanguageProvider>
    </BrowserRouter>
);

export default App;
