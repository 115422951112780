import {useReducer, useContext, createContext} from "react";
import {useHistory} from "react-router-dom";
import daySound from "../sounds/day-sound.mp3";
import nightSound from "../sounds/night-sound.mp3";

const TOTAL_NIGHTS = 10;
const DAY_SECONDS = 45;
const NIGHT_SECONDS = 15;

const initialState = {
    duration: NIGHT_SECONDS,
    isDay: false,
    day: 1,
    sound: nightSound
};

const reducer = (prevState, action) => {
    const state = {...prevState};
    switch (action) {
        case 'RESET_GAME':
            return initialState;
        case 'NEXT_NIGHT':
            state.day += 1;
            state.duration = NIGHT_SECONDS;
            state.sound = nightSound;
            state.isDay = false;
            break
        case 'NEXT_DAY':
            state.duration = DAY_SECONDS;
            state.sound = daySound;
            state.isDay = true;
            break
        default:
            throw new Error(`Unhandled action: ${action} for GameProvider reducer`)
    }
    return state;
}

const GameContext = createContext(null);

const GameProvider = ({children}) => {
    const history = useHistory();
    const [state, dispatch] = useReducer(reducer, initialState)

    const nextPhase = () => {
        if (state.day === TOTAL_NIGHTS) {
            history.push("/endgame");
        } else if (state.isDay) {
            dispatch('NEXT_NIGHT');
        } else {
            dispatch('NEXT_DAY');
        }
    };

    const resetGame = () => {
        dispatch('RESET_GAME')
    };

    const endGame = () => {
      history.push('/endgame')
    }

    return (
        <GameContext.Provider value={{nextPhase, endGame, resetGame, ...state}}>
            {children}
        </GameContext.Provider>
    );
};

export const useGame = () => {
    const context = useContext(GameContext)
    if (!context) {
        throw new Error('useGame must be used within GameProvider')
    }
    return context;
}


export default GameProvider;
