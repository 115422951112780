import {createContext, useState, useContext, useEffect} from "react";
import delve from 'dlv'
import {getDeviceLanguageCode} from "../helpers/language";
import englishLocale from '../locales/en'
import spanishLocale from '../locales/es'
import germanLocale from '../locales/de'
import frenchLocale from '../locales/fr'

const DEFAULT_LANGUAGE = "es";
const AVAILABLE_LANGUAGES = [
    {value: 'es', label: 'Español'},
    {value: 'en', label: 'English'},
    {value: 'de', label: 'Allemand'},
    {value: 'fr', label: 'Français'},
];
const LOCALES = {
    en: englishLocale,
    es: spanishLocale,
    de: germanLocale,
    fr: frenchLocale,
}

export const LanguageContext = createContext(null);

const LanguageProvider = ({children}) => {
    const [currentLanguage, setLanguage] = useState(() => {
        const storedLanguage = localStorage.getItem("language");
        const isLanguageSupported = AVAILABLE_LANGUAGES.some(({value}) => value === storedLanguage);
        return isLanguageSupported ? storedLanguage : DEFAULT_LANGUAGE;
    });

    const updateLanguage = language => {
        const isLanguageSupported = AVAILABLE_LANGUAGES.some(({value}) => value === language);
        const newLanguage = isLanguageSupported ? language : DEFAULT_LANGUAGE;
        localStorage.setItem("language", newLanguage);
        setLanguage(newLanguage);
    };

    const getTranslation = (id) => {
        return delve(LOCALES[currentLanguage], id)
    }

    useEffect(() => {
        const storedLanguage = localStorage.getItem("language");
        if (storedLanguage) {
            updateLanguage(storedLanguage);
        } else {
            getDeviceLanguageCode().then(updateLanguage);
        }
    }, []);

    return (
        <LanguageContext.Provider
            value={{
                currentLanguage,
                availableLanguages: AVAILABLE_LANGUAGES,
                updateLanguage,
                getTranslation
            }}
        >
            {children}
        </LanguageContext.Provider>
    );
};

export const useIntl = () => {
    const context = useContext(LanguageContext)
    if (!context) {
        throw new Error('useIntl must be used within IntlProvider')
    }
    return context
}


export default LanguageProvider;
