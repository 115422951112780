import raw from "raw.macro";

export default {
    home: {
        play: "Start Game",
        instructions: "Instructions"
    },
    game: {
        day: "Day",
        night: "Night",
        end: 'End Game',
    },
    endgame: {
        who: 'Who won the game?',
        assassin: 'Assassin',
        innocent: 'Innocents',
    },
    settings: {
        language: "Language"
    },
    safari: {
        unavailable: "This application does not work on Safari",
        chrome: "Use other navigator as Google Chrome instead"
    },
    lore: {
        title: "Lore",
        content: raw("./lore.md")
    },
    instructions: {
        title: "How to play",
        content: raw("./instructions.md")
    }
}
